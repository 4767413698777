<template>
  <v-card class="pa-4" :loading="loadingData">
    <v-card-title>
      Datenvisualisierung der durchschnittlichen Daten des Jahres
      {{ selectedYear }} nach Kategorie
    </v-card-title>
    <v-card-subtitle class="hide-at-print mt-3">
      <filter-generator-charts
        v-model="filter"
        @click="updateChart"
      ></filter-generator-charts>
    </v-card-subtitle>
    <v-card-text v-if="chartData != null">
      <custom-chart :chartdata="chartData"></custom-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import generateChartData from "../../utils/generateChartData";
import customChart from "../../components/customChart.vue";
import FilterGeneratorCharts from "./FilterGeneratorCharts.vue";

export default {
  data() {
    return {
      chartData: null,
      apiPath: this.generateAPIPath(),
      loadingData: true,
      filter: {},
    };
  },
  props: {
    selectedYear: {
      type: Number,
      required: true,
    },
  },
  watch: {
    selectedYear: async function () {
      await this.setupChartData();
    },
  },
  components: {
    customChart,
    FilterGeneratorCharts,
  },
  methods: {
    generateAPIPath: function () {
      return "/api/admin/getSelectedAverageYear";
    },
    updateChart: async function () {
      this.chartData = null;
      await this.setupChartData();
    },
    setupChartData: async function () {
      this.loadingData = true;
      let dataReq = [];
      dataReq.push({
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: this.selectedYear,
          filter: this.filter,
        },
        labels: [
          "Netto-Umsatz Obst, Gemüse und Kartoffeln",
          "Netto-Umsatz Sonstiges",
          "Summe aller Umsätze dieser Woche.",
        ],
        subVariable: ["nettoUmsObstGemKartof", "nettoUmsSonstige", "summe"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });

      this.chartData = await generateChartData(dataReq);
      this.loadingData = false;
    },
  },
  mounted: async function () {
    await this.setupChartData();
  },
};
</script>

<style></style>
