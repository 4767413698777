<template>
  <v-card class="pa-4">
    <v-card-title> Daten eines Jahres in Excel exportieren </v-card-title>
    <v-card-text>
      <v-select
        v-if="showSelector"
        class="hide-at-print"
        v-model="selectedYear"
        :items="generateYearsToSelectArray()"
        solo
      />
      <v-row>
        <v-col>
          <v-progress-linear
            v-if="loadingData"
            color="light-green darken-4"
            buffer-value="0"
            :value="progress"
            stream
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            block
            @click="generateFile()"
            class="hide-at-print"
            color="primary"
          >
            Daten für das Jahr {{ selectedYear }} Exportieren
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import * as XLSX from "xlsx";
import axios from "axios";
export default {
  data() {
    return {
      loadingData: false,
      selectedYear: this.year,
      progress: 0,
    };
  },
  props: {
    isAdmin: {
      type: Boolean,
      required: true,
    },
    year: {
      type: Number,
      required: false,
      default: new Date().getFullYear(),
    },
    showSelector: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  watch: {
    year: function () {
      this.selectedYear = this.year;
    },
  },
  methods: {
    generateYearsToSelectArray: function () {
      let currentYear = new Date().getFullYear();
      let years = [];
      for (let i = 0; i < 6; i++) {
        years.push(currentYear - i);
      }
      return years;
    },

    s2ab: function (s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },

    generateFile: async function () {
      this.loadingData = true;

      const reso = await axios.post(
        this.$store.state.apiUrl + "/api/admin/getAllUsers"
      );
      const allUsers = reso.data.allUser;

      const percentPerUser = 100 / allUsers.length;

      let allRevenueData = [];
      let allOrdersData = [];

      allRevenueData[0] = ["KW"];
      allOrdersData[0] = ["KW"];
      for (let i = 1; i <= 53; i++) {
        allRevenueData[0][i] = i;
        allOrdersData[0][i] = i;
      }

      for (let i = 0; i < allUsers.length; i++) {
        if (allUsers[i].exclude === true) {
          continue;
        }

        allRevenueData[i + 1] = [];
        allRevenueData[i + 1][0] = allUsers[i].companyName;

        allOrdersData[i + 1] = [];
        allOrdersData[i + 1][0] = allUsers[i].companyName;

        const res = await axios.post(
          this.$store.state.apiUrl + "/api/admin/getCompaniDataYear",
          {
            year: this.selectedYear,
            email: allUsers[i].email,
          }
        );

        for (let y = 0; y < res.data.weeksArray.length; y++) {
          allRevenueData[i + 1][res.data.weeksArray[y].kw] =
            res.data.weeksArray[y].summe;
          allOrdersData[i + 1][res.data.weeksArray[y].kw] =
            res.data.weeksArray[y].numberOfOrders;
        }
        this.progress += percentPerUser;
      }

      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "Exportig DB data",
        Author: "Umsatzmonitor 2.0",
      };

      var ws_ums = XLSX.utils.aoa_to_sheet(allRevenueData);
      var ws_noo = XLSX.utils.aoa_to_sheet(allOrdersData);

      XLSX.utils.book_append_sheet(wb, ws_ums, "Umsaetze");
      XLSX.utils.book_append_sheet(wb, ws_noo, "Anzahl der Bestellungen");

      //var wb_out =
      XLSX.writeFile(
        wb,
        "Daten Export für das Jahr " + this.selectedYear + ".xlsx"
      );

      this.progress = 0;
      this.loadingData = false;
    },
  },
  mounted: function () {},
};
</script>
<style></style>
