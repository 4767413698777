<template>
  <v-card class="pa-4" :loading="loadingData">
    <v-card-title>
      Trendlinien nach Kategorien - {{ selectedYear }}
    </v-card-title>
    <v-card-title class="mt-n5 mb-3 hide-at-print">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on"> mdi-help-circle </v-icon>
        </template>
        <span>
          Die Trendline entsteht mit der Berechnung der Umsatzentwicklung von
          einer KW zur Vorwoche, <br />
          anschließend werden dann die durchschnitte dieser Entwicklungen
          gebildet.<br />
          Abschließend werden die prozentzahlen zur Vorwoche aufaddiert.
          <br />
          Beispiel: Rein 0%, 5%, -10%, 2%.... Raus 0%, 5%, -5% , -3%....
          <br />
          Aufgrund der geringen liefermängen nach sSilvester, ist der Anstieg
          von KW 1 zu 2 immer hoch. <br />
          Werte über oder unter 200% werden ignoriert.
        </span>
      </v-tooltip>
    </v-card-title>

    <v-card-text v-if="chartData != null">
      <custom-chart :chartdata="chartData" unit=" % "></custom-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import generateChartData from "../../utils/generateChartData";
import customChart from "../../components/customChart.vue";

export default {
  data() {
    return {
      chartData: null,
      apiPath: this.generateAPIPath(),
      loadingData: true,
    };
  },
  props: {
    selectedYear: {
      type: Number,
      required: true,
    },
  },
  watch: {
    selectedYear: async function () {
      await this.setupChartData();
    },
  },
  components: {
    customChart,
  },
  methods: {
    generateAPIPath: function () {
      return "/api/admin/getTrendLineFilteredAdmin";
    },
    updateChart: async function () {
      this.chartData = null;
      await this.setupChartData();
    },
    setupChartData: async function () {
      this.loadingData = true;

      let dataReq = [];
      dataReq.push({
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: this.selectedYear,
        },
        labels: ["Ø Alle"],
        subVariable: ["diff"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });
      dataReq.push({
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: this.selectedYear,
          filter: {
            companySize: "Big",
          },
        },
        labels: ["Ø Unternehmensgröße Groß"],
        subVariable: ["diff"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });
      dataReq.push({
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: this.selectedYear,
          filter: {
            companySize: "Medium",
          },
        },
        labels: ["Ø Unternehmensgröße Mittel"],
        subVariable: ["diff"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });
      dataReq.push({
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: this.selectedYear,
          filter: {
            companySize: "Small",
          },
        },
        labels: ["Ø Unternehmensgröße Klein"],
        subVariable: ["diff"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });
      dataReq.push({
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: this.selectedYear,
          filter: {
            region: "South",
          },
        },
        labels: ["Ø Süd"],
        subVariable: ["diff"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });
      dataReq.push({
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: this.selectedYear,
          filter: {
            region: "North",
          },
        },
        labels: ["Ø Nord"],
        subVariable: ["diff"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });

      this.chartData = await generateChartData(dataReq);
      this.loadingData = false;
    },
  },
  mounted: async function () {
    await this.setupChartData();
  },
};
</script>

<style></style>
