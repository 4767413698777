<template>
  <v-card class="pa-4" :loading="loadingData">
    <v-card-title>
      Entwicklung des durchschnittlichen Lieferwertes der letzten Jahre (max. 5)
    </v-card-title>
    <v-card-subtitle class="hide-at-print mt-3">
      <filter-generator-charts
        v-model="filter"
        @click="updateChart"
      ></filter-generator-charts>
    </v-card-subtitle>
    <v-card-text v-if="!loadingData">
      <custom-chart :chartdata="cahrtData" :beginAtZero="false" />
    </v-card-text>
  </v-card>
</template>

<script>
import generateChartData from "../../utils/generateChartData";
import customChart from "../../components/customChart.vue";
import FilterGeneratorCharts from "./FilterGeneratorCharts.vue";

export default {
  name: "cart-value-average-years-comprison",
  data() {
    return {
      cahrtData: null,
      apiPath: this.generateAPIPath(),
      loadingData: true,
      filter: {},
    };
  },
  components: {
    customChart,
    FilterGeneratorCharts,
  },
  props: {},
  watch: {
    email: async function () {
      await this.setupChartData();
    },
  },
  methods: {
    generateAPIPath: function () {
      return "/api/admin/getAverageCartValueYear";
    },
    updateChart: async function () {
      this.cahrtData = null;
      await this.setupChartData();
    },
    setupChartData: async function () {
      this.loadingData = true;
      let currentYear = new Date().getFullYear();
      let dataReq = [];
      let prototypeDataReq = {
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: 0,
          filter: this.filter,
        },
        labels: [],
        subVariable: ["cartValue"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      };

      for (let i = 0; i < 5; i++) {
        let singelDataReq = JSON.parse(JSON.stringify(prototypeDataReq));
        singelDataReq.apiQuerryData.year = currentYear - i;
        singelDataReq.labels = [
          "Warenkorbwert aus dem Jahr: " + (currentYear - i),
        ];
        dataReq.push(singelDataReq);
      }
      this.cahrtData = await generateChartData(dataReq);
      this.loadingData = false;
    },
  },
  mounted: async function () {
    await this.setupChartData();
  },
};
</script>
<style></style>
