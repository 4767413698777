<template>
  <v-card class="pa-4" :loading="loadingData">
    <v-card-title>
      Datenvisualisierung des durchschnittlichen Umsatzes der letzten Jahre
      (max. 7)
    </v-card-title>
    <v-card-subtitle class="hide-at-print mt-3">
      <filter-generator-charts
        v-model="filter"
        @click="updateChart"
      ></filter-generator-charts>
    </v-card-subtitle>
    <v-card-text v-if="chartData != null">
      <custom-chart :chartdata="chartData"></custom-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import customChart from "../../components/customChart.vue";
import generateChartData from "../../utils/generateChartData";
import FilterGeneratorCharts from "./FilterGeneratorCharts.vue";

export default {
  data() {
    return {
      chartData: null,
      apiPath: this.generateAPIPath(),
      loadingData: true,
      filter: {},
    };
  },
  components: {
    customChart,
    FilterGeneratorCharts,
  },
  props: {},
  watch: {},
  methods: {
    generateAPIPath: function () {
      return "/api/admin/getSelectedAverageYear";
    },
    updateChart: async function () {
      this.chartData = null;
      await this.setupChartData();
    },
    setupChartData: async function () {
      this.loadingData = true;

      let currentYear = new Date().getFullYear();
      let dataReq = [];
      let prototypeDataReq = {
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: 0,
          filter: this.filter,
        },
        labels: [],
        subVariable: ["summe"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      };
      for (let i = 0; i < 7; i++) {
        let singelDataReq = JSON.parse(JSON.stringify(prototypeDataReq));
        singelDataReq.apiQuerryData.year = currentYear - i;
        singelDataReq.labels = ["Umsatz aus dem Jahr: " + (currentYear - i)];
        dataReq.push(singelDataReq);
      }
      this.chartData = await generateChartData(dataReq);
      this.loadingData = false;
    },
  },
  mounted: async function () {
    await this.setupChartData();
  },
};
</script>
<style>
.icon {
  order: 1;
}

.header {
  order: 2;
}
</style>
