<template>
  <v-card class="pa-4" :loading="loadingData">
    <v-card-title>
      Umsatzdurchschnitte nach Kategorien im Jahr
      {{ selectedYear }}
    </v-card-title>
    <v-card-text v-if="chartData != null">
      <custom-chart :chartdata="chartData"></custom-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import generateChartData from "../../utils/generateChartData";
import customChart from "../../components/customChart.vue";

export default {
  data() {
    return {
      chartData: null,
      apiPath: this.generateAPIPath(),
      loadingData: true,
    };
  },
  props: {
    selectedYear: {
      type: Number,
      required: true,
    },
  },
  watch: {
    selectedYear: async function () {
      await this.setupChartData();
    },
  },
  components: {
    customChart,
  },
  methods: {
    generateAPIPath: function () {
      return "/api/admin/getSelectedAverageYear";
    },
    updateChart: async function () {
      this.chartData = null;
      await this.setupChartData();
    },
    setupChartData: async function () {
      this.loadingData = true;

      let dataReq = [];
      dataReq.push({
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: this.selectedYear,
        },
        labels: ["Ø Alle"],
        subVariable: ["summe"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });
      dataReq.push({
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: this.selectedYear,
          filter: {
            companySize: "Big",
          },
        },
        labels: ["Ø Unternehmensgröße Groß"],
        subVariable: ["summe"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });
      dataReq.push({
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: this.selectedYear,
          filter: {
            companySize: "Medium",
          },
        },
        labels: ["Ø Unternehmensgröße Mittel"],
        subVariable: ["summe"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });
      dataReq.push({
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: this.selectedYear,
          filter: {
            companySize: "Small",
          },
        },
        labels: ["Ø Unternehmensgröße Klein"],
        subVariable: ["summe"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });
      dataReq.push({
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: this.selectedYear,
          filter: {
            region: "South",
          },
        },
        labels: ["Ø Süd"],
        subVariable: ["summe"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });
      dataReq.push({
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: this.selectedYear,
          filter: {
            region: "North",
          },
        },
        labels: ["Ø Nord"],
        subVariable: ["summe"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });

      this.chartData = await generateChartData(dataReq);
      this.loadingData = false;
    },
  },
  mounted: async function () {
    await this.setupChartData();
  },
};
</script>

<style></style>
