<template>
  <v-container class="print-body" ref="reportElem">
    <v-row>
      <v-col cols="12">
        <p class="text-center font-weight-light text-h3">
          Datenvisualisierung Umsatzmonitor
        </p>
        <p class="text-center font-weight-light text-h4">
          Durchschnitt aller Betriebe
        </p>
      </v-col>
    </v-row>
    <v-row class="hide-at-print">
      <v-col>
        <v-card class="pa-4">
          <v-card-title> Jahr für Visualisierung auswählen </v-card-title>
          <v-card-text>
            <v-select
              v-model="selectedYear"
              :items="generateYearsToSelectArray()"
              solo
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="hide-at-print">
      <v-col>
        <export-data-to-excel
          :isAdmin="true"
          :year="selectedYear"
        ></export-data-to-excel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <data-pool-overview :showSelector="false" :year="selectedYear" />
      </v-col>
    </v-row>
    <v-row class="new-page">
      <v-col>
        <year-comparison-chart-average />
      </v-col>
    </v-row>
    <v-row class="new-page">
      <v-col>
        <current-year-category-comp :selectedYear="selectedYear">
        </current-year-category-comp>
      </v-col>
    </v-row>
    <v-row class="new-page">
      <v-col>
        <two-year-comp-average :selectedYear="selectedYear">
        </two-year-comp-average>
      </v-col>
    </v-row>
    <v-row class="new-page">
      <v-col>
        <two-year-comp-average-quartal :selectedYear="selectedYear">
        </two-year-comp-average-quartal>
      </v-col>
    </v-row>
    <v-row class="new-page">
      <v-col>
        <trend-line-average :selectedYear="selectedYear"></trend-line-average>
      </v-col>
    </v-row>
    <v-row class="new-page">
      <v-col>
        <cart-value-average-years-comparison></cart-value-average-years-comparison>
      </v-col>
    </v-row>
    <v-row class="new-page">
      <v-col>
        <cart-value-average-years-comp-quartal></cart-value-average-years-comp-quartal>
      </v-col>
    </v-row>
    <v-row class="new-page">
      <v-col>
        <current-year-category-chart
          :selectedYear="selectedYear"
        ></current-year-category-chart>
      </v-col>
    </v-row>
    <v-btn
      class="mt-5 hide-at-print"
      color="primary"
      :loading="exportLoading"
      block
      @click="exportPDF"
    >
      Bericht als PDF Speichern</v-btn
    >
  </v-container>
</template>

<script>
import DataPoolOverview from "../../components/evaluationCharts/DataPoolOverview.vue";
import ExportDataToExcel from "../../components/adminEvaluationCharts/exportDataToExcel.vue";
import YearComparisonChartAverage from "../../components/adminEvaluationCharts/YearComparisonChartAverage.vue";
import CartValueAverageYearsComparison from "../../components/adminEvaluationCharts/CartValueAverageYearsComparison.vue";
import CartValueAverageYearsCompQuartal from "../../components/adminEvaluationCharts/CartValueAverageYearsCompQuartal.vue";
import CurrentYearCategoryChart from "../../components/adminEvaluationCharts/CurrentYearCategoryChart.vue";
import CurrentYearCategoryComp from "../../components/adminEvaluationCharts/CurrentYearCategoryComp.vue";
import TwoYearCompAverage from "../../components/adminEvaluationCharts/TwoYearCompAverage.vue";
import TwoYearCompAverageQuartal from "../../components/adminEvaluationCharts/TwoYearCompAverageQuartal.vue";
import TrendLineAverage from "../../components/adminEvaluationCharts/TrendLineAverage.vue";
export default {
  components: {
    DataPoolOverview,
    ExportDataToExcel,
    YearComparisonChartAverage,
    CartValueAverageYearsComparison,
    CartValueAverageYearsCompQuartal,
    CurrentYearCategoryChart,
    CurrentYearCategoryComp,
    TwoYearCompAverage,
    TwoYearCompAverageQuartal,
    TrendLineAverage,
  },
  data() {
    return {
      selectedYear: new Date().getFullYear(),
      exportLoading: false,
    };
  },
  methods: {
    generateYearsToSelectArray: function (
      yearPreset = new Date().getFullYear()
    ) {
      let years = [];
      for (let i = 0; i < 7; i++) {
        years.push(yearPreset - i);
      }
      return years;
    },
    exportPDF: async function () {
      window.print();
    },
  },
  async mounted() {},
};
</script>

<style>
@media print {
  canvas {
    max-width: 100%;
    width: auto !important;
    size: landscape;
  }
  .new-page {
    page-break-before: always !important;
  }
  .hide-at-print {
    display: none !important;
  }
}
@page {
  size: landscape !important;
}
</style>
