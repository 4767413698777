<template>
  <v-card class="pa-4" :loading="loadingData">
    <v-card-title>
      Entwicklung des durchschnittlichen Lieferwertes der letzten Jahre (max. 4)
      pro Quartal
    </v-card-title>
    <v-card-subtitle class="hide-at-print mt-3">
      <filter-generator-charts
        v-model="filter"
        @click="updateChart"
        :slideMin="5"
        :slideMax="500"
      ></filter-generator-charts>
    </v-card-subtitle>
    <v-card-text v-if="cahrtData != null">
      <custom-bar-chart :chartdata="cahrtData"></custom-bar-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import generateChartData from "../../utils/generateChartData";
import CustomBarChart from "../customBarChart.vue";
import FilterGeneratorCharts from "./FilterGeneratorCharts.vue";

export default {
  data() {
    return {
      cahrtData: null,
      apiPath: this.generateAPIPath(),
      loadingData: true,
      filter: {},
    };
  },
  components: {
    FilterGeneratorCharts,
    CustomBarChart,
  },
  props: {},
  watch: {},
  methods: {
    generateAPIPath: function () {
      return "/api/admin/getAverageCartValueYearQuartal";
    },
    updateChart: async function () {
      this.cahrtData = null;
      await this.setupChartData();
    },
    setupChartData: async function () {
      this.loadingData = true;

      let currentYear = new Date().getFullYear();
      let dataReq = [];
      let prototypeDataReq = {
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: 0,
          filter: this.filter,
        },
        labels: [],
        subVariable: ["cartValue"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      };
      for (let i = 0; i < 4; i++) {
        let singelDataReq = JSON.parse(JSON.stringify(prototypeDataReq));
        singelDataReq.apiQuerryData.year = currentYear - i;
        singelDataReq.labels = [
          "Warenkorbwert aus dem Jahr: " + (currentYear - i),
        ];
        dataReq.push(singelDataReq);
      }
      this.cahrtData = await generateChartData(dataReq, "Quartal ");
      this.loadingData = false;
    },
  },
  mounted: async function () {
    await this.setupChartData();
  },
};
</script>
<style>
.icon {
  order: 1;
}

.header {
  order: 2;
}
</style>
