<template>
  <v-card class="pa-4" :loading="loadingData">
    <v-card-title>
      Durchschnittliche Entwicklung von {{ yearToCompare }} zu
      {{ selectedYear }}
    </v-card-title>
    <v-card-subtitle class="hide-at-print mt-3">
      <filter-generator-charts
        v-model="filter"
        @click="updateChart"
        :slideMin="5"
        :slideMax="500"
      ></filter-generator-charts>
    </v-card-subtitle>
    <v-card-text>
      <v-select
        class="hide-at-print"
        v-model="yearToCompare"
        :items="generateYearsToSelectArray(selectedYear - 1)"
        @change="updateChart"
        solo
      />
      <v-spacer />
    </v-card-text>
    <v-card-text v-if="chartData != null">
      <custom-bar-chart :chartdata="chartData" unit=" %"></custom-bar-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import generateChartData from "../../utils/generateChartData";
import CustomBarChart from "../customBarChart.vue";
import FilterGeneratorCharts from "./FilterGeneratorCharts.vue";

export default {
  data() {
    return {
      chartData: null,
      apiPath: this.generateAPIPath(),
      loadingData: true,
      yearToCompare: this.selectedYear - 1,
      filter: {},
    };
  },
  props: {
    selectedYear: {
      type: Number,
      required: true,
    },
  },
  watch: {
    selectedYear: async function () {
      this.yearToCompare = this.selectedYear - 1;
      await this.setupChartData();
    },
  },
  components: {
    FilterGeneratorCharts,
    CustomBarChart,
  },
  methods: {
    generateAPIPath: function () {
      return "/api/admin/getAverageDataCompTowYearsQuartal";
    },
    updateChart: async function () {
      this.chartData = null;
      await this.setupChartData();
    },
    generateYearsToSelectArray: function (
      yearPreset = new Date().getFullYear()
    ) {
      let years = [];
      for (let i = 0; i < 7; i++) {
        years.push(yearPreset - i);
      }
      return years;
    },
    setupChartData: async function () {
      this.loadingData = true;

      let dataReq = [];
      dataReq.push({
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: this.selectedYear,
          yearToComp: this.yearToCompare,
          filter: this.filter,
        },
        labels: [
          "Umsatzentwicklung",
          "Umsatzentwicklung in der Kategorie Obst, Gemüse und Kartoffeln",
          "Umsatzentwicklung in der Kategorie Sonstiges",
          "Entwicklung der Auftragszahlen",
        ],
        subVariable: [
          "growthSumme",
          "growthNettoUmsObstGemKartof",
          "growthNettoUmsSonstige",
          "growthNumberOfOrders",
        ],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });

      this.chartData = await generateChartData(dataReq, "Quartal ");
      this.loadingData = false;
    },
  },
  mounted: async function () {
    await this.setupChartData();
  },
};
</script>

<style></style>
